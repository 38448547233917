<template>
  <div>
    <div v-if="loaded = true" class="container locations pb-16 fadeInFast">
      <Message
          :message="message"
          @closeAlert="message.show = false"
      ></Message>
      <v-row>
        <v-col cols="12" md="6" lg="5">
          <HeadlineDetailView
              :icon="'mdi mdi-map-marker-radius-outline'"
              :headline="location.name"
              :entity="'Location'"
          >
          </HeadlineDetailView>
        </v-col>

        <v-col cols="12" md="6" lg="7" class="text-end">
          <a class="v-btn" @click="unArchiveWedding()" v-if="location.isArchived">
            <v-btn class=" mt-2 ms-3 " width="170">
              <v-icon class="pe-2 ">
                mdi mdi-star
              </v-icon>
              Aus Archiv
            </v-btn>
          </a>
          <a class="v-btn" @click="archiveLocation()" v-else>
            <v-btn class=" mt-2 ms-3 " width="170">
              <v-icon class="pe-2 ">
                mdi mdi-archive-outline
              </v-icon>
              Archivieren
            </v-btn>
          </a>
          <a class="v-btn" :href="'tel:'+location.phone" v-if="!location.isArchived">
            <v-btn class="primary ms-3 mt-2" width="150">
              <v-icon class="pe-2">
                mdi-phone
              </v-icon>
              Anrufen
            </v-btn>
          </a>
          <a class="v-btn" :href="'mailto:'+location.email" v-if="!location.isArchived">
            <v-btn class="primary  ms-3 mt-2" width="150">
              <v-icon class="pe-2">
                mdi-mail
              </v-icon>
              E-Mail
            </v-btn>
          </a>
        </v-col>
      </v-row>
      <v-card flat class="detailCard pa-4 rounded-xl mt-6 pb-8">
        <Tab
            :tabs="['Allgemein','Dateien','Adresse','Sonstiges']"
            :register="tabs"
            @changed="tabs=$event"
        >
        </Tab>

        <div class="row pa-4" v-if="tabs === 0">
          <div class="col-12 col-sm-7">
            <KeyValueBlock
                :title="''"
                :key-values="[
                  {
                    title: 'Name',
                    value: location.name,
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'locations',
                      id: location.id,
                      row: 'name'
                    }
                  },
                  {
                    title: 'Stil',
                    value: location.stil,
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'locations',
                      id: location.id,
                      row: 'stil',
                      type: 'multipleAutoComplete',
                      autoComplete: {
                        value: location.stil,
                        items: stilNames
                      }
                    }
                  },
                  {
                    title: 'Preisklasse',
                    value: location.priceClass,
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'locations',
                      id: location.id,
                      row: 'priceClass',
                      type: 'autoComplete',
                      autoComplete: {
                        value: location.priceClass,
                        items: [{text:'€', value:1}, {text:'€€', value:2}, {text:'€€€', value:3}]
                      }
                    }
                  },
                  {
                    title: 'Fläche Innen',
                    value: location.groesseAussen +' m²',
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'locations',
                      id: location.id,
                      row: 'groesseAussen',
                      type: 'number'
                    }
                  },
                  {
                    title: 'Fläche Aussen',
                    value: location.groesseInnen +' m²',
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'locations',
                      id: location.id,
                      row: 'groesseInnen',
                      type: 'number'

                    }
                  },
                  {
                    title: 'Max. Personenanzahl',
                    value: location.maxPersonen,
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'locations',
                      id: location.id,
                      row: 'maxPersonen',
                      type: 'number'
                    }
                  },
                  {
                    title: 'Telefon',
                    value: location.phone,
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'locations',
                      id: location.id,
                      row: 'phone',
                      }
                  },
                  {
                    title: 'E-Mail Adresse',
                    value: location.email,
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'locations',
                      id: location.id,
                      row: 'email'
                    }
                  },
              ]"
                @editEntry="editEntry"
            >
            </KeyValueBlock>
            <CustomFieldBlock :entity="'location'" :user="user" :entryId="location.id"/>
          </div>
          <div class="col-12 col-xl-4 col-sm-5 ">
            <div class="text-center">
              <Map
                  v-if="location.lat && location.lng"
                  :markers="[]"
                  :mainMarkers="[location]"
                  :center="latLng(location.lat, location.lng)"
                  :zoom="10"
                  height="400"
              >
              </Map>
              <v-img
                  v-else
                  :src="require('@/assets/images/drawSVGs/map.svg')"
                  max-width="200px"
                  class="d-inline-flex"
              /><br>
              <p class="text-center" v-if="!location.lat && !location.lng">
                <small>Pflege die Koordinaten oder lasse sie ermitteln, um die Location auf der Karte anzuzeigen.
                </small>
              </p>
            </div>
          </div>
          <div class="col-12 col-xl-1">

          </div>
        </div>
        <div class="row pa-4" v-if="tabs===1">
          <v-col>
            <UploadFile
                :locationId="location.id"
            ></UploadFile>
          </v-col>
        </div>
        <VirtualScrollFiles
            v-if="tabs===1"
            :items="files"
            :vendorId="location.id"
        ></VirtualScrollFiles>
        <div class="row pa-4" v-if="tabs === 2">
          <div class="col-12 col-sm-7">
            <KeyValueBlock
                :key-values="[
                  {
                    title: 'Straße',
                    value: location.street,
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'locations',
                      id: location.id,
                      row: 'street'
                    }
                  },
                  {
                    title: 'Hausnummer',
                    value: location.houseNumber,
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'locations',
                      id: location.id,
                      row: 'houseNumber'
                    }
                  },
                  {
                    title: 'Postleitzahl',
                    value: location.plz,
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'locations',
                      id: location.id,
                      row: 'plz'
                    }
                  },
                  {
                    title: 'Ort',
                    value: location.city,
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'locations',
                      id: location.id,
                      row: 'city'
                    }
                  },
                ]"
                @editEntry="editEntry">
            </KeyValueBlock>
            <h4 class="pt-8 pb-4">Koordinaten <v-btn class="ms-3 primary" @click="getCoordinates()">Ermitteln</v-btn>
            </h4>
            <KeyValueBlock
                v-if="latLngLoaded"
                :key-values="[
                  {
                    title: 'Lat',
                    value: location.lat,
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'locations',
                      id: location.id,
                      row: 'lat',
                      type: 'number'
                    }
                  },
                  {
                    title: 'Lng',
                    value: location.lng,
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'locations',
                      id: location.id,
                      row: 'lng',
                      type: 'number'

                    }
                  },
                ]"
                @editEntry="editEntry">
            </KeyValueBlock>
            <Spinner v-else>

            </Spinner>
          </div>
          <div class="col-12 col-sm-5 text-center">
              <Map
                  v-if="location.lat && location.lng"
                  :markers="[]"
                  :mainMarkers="[location]"
                  :center="latLng(location.lat, location.lng)"
                  :zoom="10"
                  height="400"
              >
              </Map>
              <v-img
                  v-else
                  :src="require('@/assets/images/drawSVGs/map.svg')"
                  max-width="200px"
                  class="d-inline-flex"
              /><br>
              <p class="text-center" v-if="!location.lat && !location.lng">
                <small>Pflege die Koordinaten oder lasse sie ermitteln, um die Location auf der Karte anzuzeigen.
                </small>
              </p>

            <div v-if="location.street && location.street && location.street ">
              <p>
                {{ location.street }} {{ location.houseNumber }}<br>
                {{ location.plz }} {{ location.city }}
              </p>
            </div>
          </div>
        </div>
        <div class="row pa-4" v-if="tabs === 3">
          <div class="col-12 col-sm-7">
            <KeyValueBlock
                :key-values="[
              {title: 'Erstellt am', value: getStringDate(location.created), isEdit: false},
              ]">
            </KeyValueBlock>
          </div>
          <div class="col-12 col-sm-5">
          </div>
        </div>
      </v-card>
    </div>

    <CreateWedding
        :dialog="dialogCreateWedding"
        :openAfterCreate="true"
        :locationSelection="location.id"
        @hideDialog="hideDialog"
    ></CreateWedding>
  </div>
</template>
<script>

import {mapGetters} from "vuex"
import {date2String, error, msgObj, success} from "@/helper/helper";
import {latLng} from "leaflet";

const Map = () => import("@/components/generalUI/Map");
const Tab = () => import("@/components/generalUI/Tab");
const Message = () => import("@/components/generalUI/Message");
const KeyValueBlock = () => import("@/components/detailView/KeyValueBlock");
const HeadlineDetailView = () => import("@/components/detailView/HeadlineDetailView");
const CustomFieldBlock = () => import("@/components/detailView/CustomFieldBlock");
const CreateWedding = () => import("@/components/dialog/createDialogs/CreateWedding");
const Spinner = () => import("@/components/generalUI/Spinner");
const VirtualScrollFiles = () => import("@/components/entities/wedding/VirtualScrollWeddingFiles");
const UploadFile = () => import("@/components/entities/wedding/UploadWeddingFile");
export default {
  name: 'LocationDetail',
  components: {
    HeadlineDetailView,
    Map,
    KeyValueBlock,
    Message,
    CustomFieldBlock,
    CreateWedding,
    Tab,
    Spinner,
    VirtualScrollFiles,
    UploadFile
  },
  mounted() {
    window.scrollTo(0, 0)
    this.$store.dispatch('locations/getLocation', {
      uid: this.user.id,
      locationId: this.$route.params.id
    }).then(() => {
      this.loaded = true
      this.$store.dispatch('settings/getStatusForSelection', {
        uid: this.user.id
      }).catch((err) => {
        this.message = error(err)
      })
      this.$store.dispatch('settings/getStil', {
        uid: this.user.id
      }).catch((err) => {
        this.message = error(err)
      })
      this.$store.dispatch('file/getFilesForLocation', {
        uid: this.user.id,
        locationId: this.$route.params.id
      }).catch((err) => {
        this.message = error(err)
      })
    }).catch((err) => {
      this.message = error(err)
    })

  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('locations', {
      location: 'location',
    }),
    ...mapGetters('settings', {
      stilNames: 'stilNames',
    }),
    ...mapGetters('file', {
      files: 'files',
    })

  },
  data() {
    return {
      dialogCreateWedding: false,
      tabs: 0,
      loaded: false,
      dialog: false,
      message: msgObj(),
      wedding: {},
      latLngLoaded: true
    }
  },
  methods: {
    latLng,
    getCoordinates() {
      this.latLngLoaded = false
      this.$store.dispatch('locations/getCoordinates', {
        uid: this.user.id,
        address: this.location.street + ' ' + this.location.houseNumber + ' ' + this.location.plz + ' ' + this.location.city
      }).then((res) => {
        if(res.lat && res.lng){
          this.$store.dispatch('customer/editField', {
            entity: "locations",
            uid: this.user.id,
            id: this.$route.params.id,
            newValue: res.lat,
            row: 'lat'
          }).then(()=>{
            this.$store.dispatch('customer/editField', {
              entity: "locations",
              uid: this.user.id,
              id: this.$route.params.id,
              newValue: res.lng,
              row: 'lng'
            }).then(()=>{
              this.$store.dispatch('locations/getLocation', {
                uid: this.user.id,
                locationId: this.$route.params.id
              })
              this.latLngLoaded = true
              this.message = success('Koordinaten aktualisiert')
            })
          })
        }else{
          this.latLngLoaded = true
          this.message = error('Koordinaten konnten nicht ermittelt werden.')
        }
      }).catch((err) => {
        this.latLngLoaded = true
        this.message = error(err)
      })
    },
    hideDialog() {
      this.dialogCreateWedding = false
    },
    getStringDate(date) {
      return date2String(date)
    },
    openWedding(id) {
      this.$router.push('/weddings/wedding/' + id)
    },
    archiveLocation() {
      this.$store.dispatch('customer/editField', {
        entity: "locations",
        uid: this.user.id,
        id: this.$route.params.id,
        newValue: true,
        row: 'isArchived'
      }).then(() => {
        this.$router.push('/locations')
      })
    },
    unArchiveWedding() {
      this.$store.dispatch('customer/editField', {
        entity: "locations",
        uid: this.$route.params.id,
        id: this.$route.params.id,
        newValue: false,
        row: 'isArchived'
      }).then(() => {
        this.$router.push('/locations')
      })
    },
    editEntry(payload) {
      console.log(payload)
      this.$store.dispatch('customer/editField', {
        entity: "locations",
        uid: this.user.id,
        id: payload.id,
        newValue: payload.newValue,
        row: payload.row
      }).then(() => {
        this.$store.dispatch('locations/getLocation', {locationId: payload.id, uid: this.user.id}
        ).catch((err) => {
          this.message = error(err)
        })
      }).catch((err) => {
        this.message = error(err)
      })
    }
  }
}
</script>
<style>


</style>
