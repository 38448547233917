var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loaded = true)?_c('div',{staticClass:"container locations pb-16 fadeInFast"},[_c('Message',{attrs:{"message":_vm.message},on:{"closeAlert":function($event){_vm.message.show = false}}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"5"}},[_c('HeadlineDetailView',{attrs:{"icon":'mdi mdi-map-marker-radius-outline',"headline":_vm.location.name,"entity":'Location'}})],1),_c('v-col',{staticClass:"text-end",attrs:{"cols":"12","md":"6","lg":"7"}},[(_vm.location.isArchived)?_c('a',{staticClass:"v-btn",on:{"click":function($event){return _vm.unArchiveWedding()}}},[_c('v-btn',{staticClass:"mt-2 ms-3",attrs:{"width":"170"}},[_c('v-icon',{staticClass:"pe-2"},[_vm._v(" mdi mdi-star ")]),_vm._v(" Aus Archiv ")],1)],1):_c('a',{staticClass:"v-btn",on:{"click":function($event){return _vm.archiveLocation()}}},[_c('v-btn',{staticClass:"mt-2 ms-3",attrs:{"width":"170"}},[_c('v-icon',{staticClass:"pe-2"},[_vm._v(" mdi mdi-archive-outline ")]),_vm._v(" Archivieren ")],1)],1),(!_vm.location.isArchived)?_c('a',{staticClass:"v-btn",attrs:{"href":'tel:'+_vm.location.phone}},[_c('v-btn',{staticClass:"primary ms-3 mt-2",attrs:{"width":"150"}},[_c('v-icon',{staticClass:"pe-2"},[_vm._v(" mdi-phone ")]),_vm._v(" Anrufen ")],1)],1):_vm._e(),(!_vm.location.isArchived)?_c('a',{staticClass:"v-btn",attrs:{"href":'mailto:'+_vm.location.email}},[_c('v-btn',{staticClass:"primary ms-3 mt-2",attrs:{"width":"150"}},[_c('v-icon',{staticClass:"pe-2"},[_vm._v(" mdi-mail ")]),_vm._v(" E-Mail ")],1)],1):_vm._e()])],1),_c('v-card',{staticClass:"detailCard pa-4 rounded-xl mt-6 pb-8",attrs:{"flat":""}},[_c('Tab',{attrs:{"tabs":['Allgemein','Dateien','Adresse','Sonstiges'],"register":_vm.tabs},on:{"changed":function($event){_vm.tabs=$event}}}),(_vm.tabs === 0)?_c('div',{staticClass:"row pa-4"},[_c('div',{staticClass:"col-12 col-sm-7"},[_c('KeyValueBlock',{attrs:{"title":'',"key-values":[
                {
                  title: 'Name',
                  value: _vm.location.name,
                  isEdit: false,
                  editBtn:{
                    icon:'mdi-check-circle',
                    entity: 'locations',
                    id: _vm.location.id,
                    row: 'name'
                  }
                },
                {
                  title: 'Stil',
                  value: _vm.location.stil,
                  isEdit: false,
                  editBtn:{
                    icon:'mdi-check-circle',
                    entity: 'locations',
                    id: _vm.location.id,
                    row: 'stil',
                    type: 'multipleAutoComplete',
                    autoComplete: {
                      value: _vm.location.stil,
                      items: _vm.stilNames
                    }
                  }
                },
                {
                  title: 'Preisklasse',
                  value: _vm.location.priceClass,
                  isEdit: false,
                  editBtn:{
                    icon:'mdi-check-circle',
                    entity: 'locations',
                    id: _vm.location.id,
                    row: 'priceClass',
                    type: 'autoComplete',
                    autoComplete: {
                      value: _vm.location.priceClass,
                      items: [{text:'€', value:1}, {text:'€€', value:2}, {text:'€€€', value:3}]
                    }
                  }
                },
                {
                  title: 'Fläche Innen',
                  value: _vm.location.groesseAussen +' m²',
                  isEdit: false,
                  editBtn:{
                    icon:'mdi-check-circle',
                    entity: 'locations',
                    id: _vm.location.id,
                    row: 'groesseAussen',
                    type: 'number'
                  }
                },
                {
                  title: 'Fläche Aussen',
                  value: _vm.location.groesseInnen +' m²',
                  isEdit: false,
                  editBtn:{
                    icon:'mdi-check-circle',
                    entity: 'locations',
                    id: _vm.location.id,
                    row: 'groesseInnen',
                    type: 'number'

                  }
                },
                {
                  title: 'Max. Personenanzahl',
                  value: _vm.location.maxPersonen,
                  isEdit: false,
                  editBtn:{
                    icon:'mdi-check-circle',
                    entity: 'locations',
                    id: _vm.location.id,
                    row: 'maxPersonen',
                    type: 'number'
                  }
                },
                {
                  title: 'Telefon',
                  value: _vm.location.phone,
                  isEdit: false,
                  editBtn:{
                    icon:'mdi-check-circle',
                    entity: 'locations',
                    id: _vm.location.id,
                    row: 'phone',
                    }
                },
                {
                  title: 'E-Mail Adresse',
                  value: _vm.location.email,
                  isEdit: false,
                  editBtn:{
                    icon:'mdi-check-circle',
                    entity: 'locations',
                    id: _vm.location.id,
                    row: 'email'
                  }
                } ]},on:{"editEntry":_vm.editEntry}}),_c('CustomFieldBlock',{attrs:{"entity":'location',"user":_vm.user,"entryId":_vm.location.id}})],1),_c('div',{staticClass:"col-12 col-xl-4 col-sm-5"},[_c('div',{staticClass:"text-center"},[(_vm.location.lat && _vm.location.lng)?_c('Map',{attrs:{"markers":[],"mainMarkers":[_vm.location],"center":_vm.latLng(_vm.location.lat, _vm.location.lng),"zoom":10,"height":"400"}}):_c('v-img',{staticClass:"d-inline-flex",attrs:{"src":require('@/assets/images/drawSVGs/map.svg'),"max-width":"200px"}}),_c('br'),(!_vm.location.lat && !_vm.location.lng)?_c('p',{staticClass:"text-center"},[_c('small',[_vm._v("Pflege die Koordinaten oder lasse sie ermitteln, um die Location auf der Karte anzuzeigen. ")])]):_vm._e()],1)]),_c('div',{staticClass:"col-12 col-xl-1"})]):_vm._e(),(_vm.tabs===1)?_c('div',{staticClass:"row pa-4"},[_c('v-col',[_c('UploadFile',{attrs:{"locationId":_vm.location.id}})],1)],1):_vm._e(),(_vm.tabs===1)?_c('VirtualScrollFiles',{attrs:{"items":_vm.files,"vendorId":_vm.location.id}}):_vm._e(),(_vm.tabs === 2)?_c('div',{staticClass:"row pa-4"},[_c('div',{staticClass:"col-12 col-sm-7"},[_c('KeyValueBlock',{attrs:{"key-values":[
                {
                  title: 'Straße',
                  value: _vm.location.street,
                  isEdit: false,
                  editBtn:{
                    icon:'mdi-check-circle',
                    entity: 'locations',
                    id: _vm.location.id,
                    row: 'street'
                  }
                },
                {
                  title: 'Hausnummer',
                  value: _vm.location.houseNumber,
                  isEdit: false,
                  editBtn:{
                    icon:'mdi-check-circle',
                    entity: 'locations',
                    id: _vm.location.id,
                    row: 'houseNumber'
                  }
                },
                {
                  title: 'Postleitzahl',
                  value: _vm.location.plz,
                  isEdit: false,
                  editBtn:{
                    icon:'mdi-check-circle',
                    entity: 'locations',
                    id: _vm.location.id,
                    row: 'plz'
                  }
                },
                {
                  title: 'Ort',
                  value: _vm.location.city,
                  isEdit: false,
                  editBtn:{
                    icon:'mdi-check-circle',
                    entity: 'locations',
                    id: _vm.location.id,
                    row: 'city'
                  }
                } ]},on:{"editEntry":_vm.editEntry}}),_c('h4',{staticClass:"pt-8 pb-4"},[_vm._v("Koordinaten "),_c('v-btn',{staticClass:"ms-3 primary",on:{"click":function($event){return _vm.getCoordinates()}}},[_vm._v("Ermitteln")])],1),(_vm.latLngLoaded)?_c('KeyValueBlock',{attrs:{"key-values":[
                {
                  title: 'Lat',
                  value: _vm.location.lat,
                  isEdit: false,
                  editBtn:{
                    icon:'mdi-check-circle',
                    entity: 'locations',
                    id: _vm.location.id,
                    row: 'lat',
                    type: 'number'
                  }
                },
                {
                  title: 'Lng',
                  value: _vm.location.lng,
                  isEdit: false,
                  editBtn:{
                    icon:'mdi-check-circle',
                    entity: 'locations',
                    id: _vm.location.id,
                    row: 'lng',
                    type: 'number'

                  }
                } ]},on:{"editEntry":_vm.editEntry}}):_c('Spinner')],1),_c('div',{staticClass:"col-12 col-sm-5 text-center"},[(_vm.location.lat && _vm.location.lng)?_c('Map',{attrs:{"markers":[],"mainMarkers":[_vm.location],"center":_vm.latLng(_vm.location.lat, _vm.location.lng),"zoom":10,"height":"400"}}):_c('v-img',{staticClass:"d-inline-flex",attrs:{"src":require('@/assets/images/drawSVGs/map.svg'),"max-width":"200px"}}),_c('br'),(!_vm.location.lat && !_vm.location.lng)?_c('p',{staticClass:"text-center"},[_c('small',[_vm._v("Pflege die Koordinaten oder lasse sie ermitteln, um die Location auf der Karte anzuzeigen. ")])]):_vm._e(),(_vm.location.street && _vm.location.street && _vm.location.street )?_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.location.street)+" "+_vm._s(_vm.location.houseNumber)),_c('br'),_vm._v(" "+_vm._s(_vm.location.plz)+" "+_vm._s(_vm.location.city)+" ")])]):_vm._e()],1)]):_vm._e(),(_vm.tabs === 3)?_c('div',{staticClass:"row pa-4"},[_c('div',{staticClass:"col-12 col-sm-7"},[_c('KeyValueBlock',{attrs:{"key-values":[
            {title: 'Erstellt am', value: _vm.getStringDate(_vm.location.created), isEdit: false} ]}})],1),_c('div',{staticClass:"col-12 col-sm-5"})]):_vm._e()],1)],1):_vm._e(),_c('CreateWedding',{attrs:{"dialog":_vm.dialogCreateWedding,"openAfterCreate":true,"locationSelection":_vm.location.id},on:{"hideDialog":_vm.hideDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }